export const CheckType = {
  HTTPS: 'https',
  HTTP: 'http',
  SOCKET: 'socket',
  ICMP: 'icmp',
  TCP: 'tcp',
  UDP: 'udp',
  DNS: 'dns'
  // API: "api" // Not available yet
};

export const SupportedProtocolRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|udp:\/\/|tcp:\/\/|dns-resolve:\/\/|icmp-ping:\/\/|ws:\/\/|wss:\/\/|https:\/\/)/;

export const CheckTypeProtocolPrefix = {
  [CheckType.HTTPS]: 'https://',
  [CheckType.HTTP]: 'http://',
  [CheckType.SOCKET]: 'ws://',
  [CheckType.ICMP]: 'icmp-ping://',
  [CheckType.TCP]: 'tcp://',
  [CheckType.UDP]: 'udp://',
  [CheckType.DNS]: 'dns-resolve://'
};

export const CheckTypeProtocolPrefixMultiSockets = {
  [CheckType.HTTPS]: 'https://',
  [CheckType.HTTP]: 'http://',
  [CheckType.ICMP]: 'icmp-ping://',
  [CheckType.TCP]: 'tcp://',
  [CheckType.UDP]: 'udp://',
  [CheckType.DNS]: 'dns-resolve://',
  [CheckType.SOCKET]: ['wss://', 'ws://']
};

export const CheckInputCopy = {
  [CheckType.HTTPS]: {
    label: 'Check URL',
    placeholder: 'https://www.domain.com/page'
  },
  [CheckType.HTTP]: {
    label: 'Check URL',
    placeholder: 'https://www.domain.com/page'
  },
  [CheckType.SOCKET]: {
    label: 'WS or WSS URL',
    placeholder: 'ws://www.yourdomain.com/sockets/notifications/'
  },
  [CheckType.ICMP]: {
    label: 'IP address/ Host name',
    placeholder: '93.184.216.34 or www.yourdomain.com'
  },
  [CheckType.TCP]: {
    label: 'IP address/ Host name',
    placeholder: '93.184.216.34 or www.yourdomain.com'
  },
  [CheckType.UDP]: {
    label: 'IP address/ Host name',
    placeholder: '93.184.216.34 or www.yourdomain.com'
  },
  [CheckType.DNS]: {
    label: 'Host name',
    placeholder: 'www.domain.com'
  }
};

export const CheckTypeCopy = {
  [CheckType.HTTPS]: 'HTTP /HTTPS',
  [CheckType.HTTP]: 'HTTP /HTTPS',
  [CheckType.SOCKET]: 'Web sockets',
  [CheckType.ICMP]: 'ICMP ping',
  [CheckType.TCP]: 'TCP',
  [CheckType.UDP]: 'UDP',
  [CheckType.DNS]: 'DNS',
  [CheckType.API]: 'API monitoring'
};

const CheckTypeCat = {
  HTTPS: 'https',
  SOCKET: 'socket',
  ICMP: 'icmp',
  TCP: 'tcp',
  UDP: 'udp',
  DNS: 'dns'
};

export const checkTypeOptions = Object.values(CheckTypeCat).map(type => ({
  value: type,
  label: CheckTypeCopy[type]
}));

export const PerfAlert = {
  ON: true,
  OFF: false
};

export const PerfAlertCopy = {
  [PerfAlert.ON]: 'ON',
  [PerfAlert.OFF]: 'OFF'
};

export const PerfAlertOptions = Object.values(PerfAlert).map(type => ({
  value: type,
  label: PerfAlertCopy[type]
}));

export const PerfAlertDebounce = {
  INSTANT: 'INSTANT',
  ONEMIN: 'ONEMIN',
  TWOMIN: 'TWOMIN',
  THREEMIN: 'THREEMIN',
  FOURMIN: 'FOURMIN',
  FIVEMIN: 'FIVEMIN',
  TENMIN: 'TENMIN',
  FIFTEENMIN: 'FIFTEENMIN',
  THIRTYMIN: 'THIRTYMIN'
};

export const PerfAlertDebounceCopy = {
  [PerfAlertDebounce.INSTANT]: 'instantly',
  [PerfAlertDebounce.ONEMIN]: '1',
  [PerfAlertDebounce.TWOMIN]: '2',
  [PerfAlertDebounce.THREEMIN]: '3',
  [PerfAlertDebounce.FOURMIN]: '4',
  [PerfAlertDebounce.FIVEMIN]: '5',
  [PerfAlertDebounce.TENMIN]: '10',
  [PerfAlertDebounce.FIFTEENMIN]: '15',
  [PerfAlertDebounce.THIRTYMIN]: '30'
};

export const PerfAlertDebounceValue = {
  [PerfAlertDebounce.INSTANT]: 0,
  [PerfAlertDebounce.ONEMIN]: 60,
  [PerfAlertDebounce.TWOMIN]: 120,
  [PerfAlertDebounce.THREEMIN]: 180,
  [PerfAlertDebounce.FOURMIN]: 240,
  [PerfAlertDebounce.FIVEMIN]: 300,
  [PerfAlertDebounce.TENMIN]: 600,
  [PerfAlertDebounce.FIFTEENMIN]: 900,
  [PerfAlertDebounce.THIRTYMIN]: 1800
};

export const AlertDebounce = {
  INSTANT: 'INSTANT',
  ONEMIN: 'ONEMIN',
  TWOMIN: 'TWOMIN',
  THREEMIN: 'THREEMIN',
  FOURMIN: 'FOURMIN',
  FIVEMIN: 'FIVEMIN',
  TENMIN: 'TENMIN',
  FIFTEENMIN: 'FIFTEENMIN',
  THIRTYMIN: 'THIRTYMIN'
};

export const AlertDebounceValues = {
  [AlertDebounce.INSTANT]: 0,
  [AlertDebounce.ONEMIN]: 60,
  [AlertDebounce.TWOMIN]: 120,
  [AlertDebounce.THREEMIN]: 180,
  [AlertDebounce.FOURMIN]: 240,
  [AlertDebounce.FIVEMIN]: 300,
  [AlertDebounce.TENMIN]: 600,
  [AlertDebounce.FIFTEENMIN]: 900,
  [AlertDebounce.THIRTYMIN]: 1800
};

export const AlertDebounceCopy = {
  [AlertDebounce.INSTANT]: 'Instantly',
  [AlertDebounce.ONEMIN]: '1',
  [AlertDebounce.TWOMIN]: '2',
  [AlertDebounce.THREEMIN]: '3',
  [AlertDebounce.FOURMIN]: '4',
  [AlertDebounce.FIVEMIN]: '5',
  [AlertDebounce.TENMIN]: '10',
  [AlertDebounce.FIFTEENMIN]: '15',
  [AlertDebounce.THIRTYMIN]: '30'
};

export const SslAlert = {
  ON: 'ON',
  OFF: 'OFF'
};

export const SslAlertCopy = {
  [SslAlert.ON]: 'ON',
  [SslAlert.OFF]: 'OFF'
};

export const SslAlertValue = {
  [SslAlert.ON]: 'false',
  [SslAlert.OFF]: 'true'
};

export const SslAlertOptions = Object.values(SslAlert).map(type => ({
  value: SslAlertValue[type],
  label: SslAlertCopy[type]
}));

export const SslExpiry = {
  SEVEN: 'seven_days',
  FIFTEEN: 'fifteen_days',
  EXPIRY: 'on_expiry'
};

export const SslExpiryCopy = {
  [SslExpiry.SEVEN]: '7 days in advance',
  [SslExpiry.FIFTEEN]: '15 days in advance',
  [SslExpiry.EXPIRY]: 'On expiry'
};

export const SslExpiryOptions = Object.values(SslExpiry).map(type => ({
  value: type,
  label: SslExpiryCopy[type]
}));

export const CheckInterval = {
  ONEMIN: 60,
  FIVEMIN: 300
  // TENMIN: 600 //This is not available yet
};

export const AlertDebounceOptions = {
  [CheckInterval.ONEMIN]: [
    {
      id: AlertDebounce.INSTANT,
      prepend: 'Alert ',
      append: ' when the check is down',
      options: [
        {
          value: AlertDebounceValues[AlertDebounce.INSTANT],
          label: AlertDebounceCopy[AlertDebounce.INSTANT]
        }
      ]
    },
    {
      id: 'INTERVAL',
      prepend: 'Alert if the server is unresponsive for ',
      append: ' Min(s)',
      options: Object.values(AlertDebounce)
        .map(type => ({
          value: AlertDebounceValues[type],
          label: AlertDebounceCopy[type]
        }))
        .slice(1)
    }
  ],
  [CheckInterval.FIVEMIN]: [
    {
      id: AlertDebounce.INSTANT,
      prepend: 'Alert ',
      append: ' when the check is down',
      options: [
        {
          value: AlertDebounceValues[AlertDebounce.INSTANT],
          label: AlertDebounceCopy[AlertDebounce.INSTANT]
        }
      ]
    },
    {
      id: 'INTERVAL',
      prepend: 'Alert if the server is unresponsive for ',
      append: ' Min(s)',
      options: Object.values(AlertDebounce)
        .map(type => ({
          value: AlertDebounceValues[type],
          label: AlertDebounceCopy[type]
        }))
        .slice(1)
        .filter(val => val.value % 300 === 0)
    }
  ]
};

export const PerfAlertDebounceOptions = {
  [CheckInterval.ONEMIN]: [
    {
      id: PerfAlertDebounce.INSTANT,
      prepend: 'Alert ',
      append: ' when the performance degrades',
      options: [
        {
          value: PerfAlertDebounceValue[PerfAlertDebounce.INSTANT],
          label: PerfAlertDebounceCopy[PerfAlertDebounce.INSTANT]
        }
      ]
    },
    {
      id: 'INTERVAL',
      prepend: 'Alert when the performance is continuously degraded for ',
      append: ' Min(s)',
      options: Object.values(PerfAlertDebounce)
        .map(type => ({
          value: PerfAlertDebounceValue[type],
          label: PerfAlertDebounceCopy[type]
        }))
        .slice(1)
    }
  ],
  [CheckInterval.FIVEMIN]: [
    {
      id: PerfAlertDebounce.INSTANT,
      prepend: 'Alert ',
      append: ' when the performance degrades',
      options: [
        {
          value: PerfAlertDebounceValue[PerfAlertDebounce.INSTANT],
          label: PerfAlertDebounceCopy[PerfAlertDebounce.INSTANT]
        }
      ]
    },
    {
      id: 'INTERVAL',
      prepend: 'Alert when the performance is continuously degraded for ',
      append: ' Min(s)',
      options: Object.values(PerfAlertDebounce)
        .map(type => ({
          value: PerfAlertDebounceValue[type],
          label: PerfAlertDebounceCopy[type]
        }))
        .slice(1)
        .filter(val => val.value % 300 === 0)
    }
  ]
};

export const CheckIntervalCopy = {
  [CheckInterval.ONEMIN]: '1 min',
  [CheckInterval.FIVEMIN]: '5 mins',
  [CheckInterval.TENMIN]: '10 mins'
};

export const CheckIntervalOptions = Object.values(CheckInterval).map(type => ({
  value: type,
  label: CheckIntervalCopy[type]
}));

export const ResponseStringValues = {
  CONTAINS: 'success_string',
  NOTCONTAINS: 'failure_string'
};

export const ResponseStringCheckKey = [
  { label: 'Contains', value: ResponseStringValues.CONTAINS },
  { label: "Doesn't contain", value: ResponseStringValues.NOTCONTAINS }
];

export const ResponseCodeValues = {
  CONSIDERUP: 'whitelisted_status_codes_csv',
  CONSIDERDOWN: 'blacklisted_status_codes_csv'
};

export const ResponseCodeCheckKey = [
  { label: 'Consider up', value: ResponseCodeValues.CONSIDERUP },
  { label: 'Consider down', value: ResponseCodeValues.CONSIDERDOWN }
];

export const ALERT_SECTIONS = {
  CHECK_INFO: 'check-info',
  ALERT_SETTING: 'alert-settings',
  AUTH_PARAMS: 'authentication-parameters',
  CUSTOM_HEADER: 'custom-header',
  MONITORING_SETTING: 'monitoring-settings',
  ALERT_FINE_TUNE: 'alert-fine-tune',
  PERF_ALERT: 'performance-alerting',
  SSL: 'ssl'
};

export const CHECK_FORM_CONFIG = {
  ids: [
    ALERT_SECTIONS.CHECK_INFO,
    ALERT_SECTIONS.ALERT_SETTING,
    ALERT_SECTIONS.AUTH_PARAMS,
    ALERT_SECTIONS.CUSTOM_HEADER,
    ALERT_SECTIONS.MONITORING_SETTING,
    ALERT_SECTIONS.ALERT_FINE_TUNE,
    ALERT_SECTIONS.PERF_ALERT,
    ALERT_SECTIONS.SSL
  ],
  collection: {
    basic: {
      label: 'Basic information',
      id: ALERT_SECTIONS.CHECK_INFO,
      sections: [
        ALERT_SECTIONS.CHECK_INFO,
        ALERT_SECTIONS.ALERT_SETTING,
        ALERT_SECTIONS.AUTH_PARAMS,
        ALERT_SECTIONS.CUSTOM_HEADER,
        ALERT_SECTIONS.MONITORING_SETTING
      ],
      [ALERT_SECTIONS.CHECK_INFO]: {
        id: ALERT_SECTIONS.CHECK_INFO,
        label: 'Basic information',
        hidden: true
      },
      [ALERT_SECTIONS.ALERT_SETTING]: {
        id: ALERT_SECTIONS.ALERT_SETTING,
        label: 'Alert settings'
        // subtitle:
        //   'Email address of the people who should be notified during abnormal performance'
      },
      [ALERT_SECTIONS.AUTH_PARAMS]: {
        id: ALERT_SECTIONS.AUTH_PARAMS,
        label: 'Authentication parameters',
        hideFor: [CheckType.SOCKET, CheckType.ICMP],
        subtitle:
          'Enter the authentication credentials required to log in and perform the check'
      },
      [ALERT_SECTIONS.CUSTOM_HEADER]: {
        id: ALERT_SECTIONS.CUSTOM_HEADER,
        label: 'Custom HTTP header',
        subtitle: 'Add custom header parameters and keys',
        hideFor: [
          CheckType.SOCKET,
          CheckType.ICMP,
          CheckType.DNS,
          CheckType.TCP,
          CheckType.UDP
        ]
      },
      [ALERT_SECTIONS.MONITORING_SETTING]: {
        id: ALERT_SECTIONS.MONITORING_SETTING,
        label: 'Advanced monitoring settings',
        subtitle:
          "Configure your Check's monitoring locaton and its Apdex response threshold"
      }
    },
    alert: {
      label: 'Alert fine tuning',
      id: ALERT_SECTIONS.ALERT_FINE_TUNE,
      sections: [ALERT_SECTIONS.ALERT_FINE_TUNE, ALERT_SECTIONS.PERF_ALERT],
      [ALERT_SECTIONS.ALERT_FINE_TUNE]: {
        id: ALERT_SECTIONS.ALERT_FINE_TUNE,
        label: 'Alert fine tuning',
        hidden: true,
        subtitle:
          'Configure alerts based on a string in the response, or error-codes. Also define performance-based alerting'
      },
      [ALERT_SECTIONS.PERF_ALERT]: {
        id: ALERT_SECTIONS.PERF_ALERT,
        label: 'Performance Alerting',
        subtitle:
          'Configure the request timeout threshold and define the alerting rules'
      }
    },
    ssl: {
      label: 'SSL Monitoring',
      hidden: true,
      id: ALERT_SECTIONS.SSL,
      sections: [ALERT_SECTIONS.SSL],
      [ALERT_SECTIONS.SSL]: {
        id: ALERT_SECTIONS.SSL,
        label: 'SSL Monitoring',
        hidden: true,
        subtitle: 'Consider the website to be down if the SSL check fails'
      }
    }
  }
};
